@import '/src/scss/_variables';
@import '/src/scss/mixins';

.benefits-banner {
  background: rgb(13, 96, 125);
  color: #fff;
  margin-top: 0;
  padding: 0.2em;
  font-size: 2em;
  font-weight: 600;
  z-index: 1;
  position: relative;

  > .inner {
    max-width: 980px;
  }

  .highlight {
    color: theme-color('error');
  }

  &:after {
    background: rgba(#333, 0.7);
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
  }
}

.page.BenefitsPage {
  margin-top: 0;
  padding: 1px 20px 20vh;

  .page-wrapper {
    margin-top: 0;
    max-width: 980px;
    font-size: 1.2em;
    padding: 0;
    background: none;
    box-shadow: none;
  }

  .page-body {
    display: flex;
    flex-flow: column;
  }

  .section-header {
    margin-bottom: 3em;
    padding: 0 2em;

    .section-title {
      font-size: 3em;
      font-weight: 600;
      line-height: 1;
      z-index: 1;
      padding: 0;
      margin: 0;
      color: theme-color('success-lighter');
      text-shadow: 3px 3px 10px #333;
    }
    .section-title + p {
      margin-top: 1.5em;
      color: #fff;
    }

    &.top {
      margin-top: 3em;
      text-align: center;
      color: #fff;
      align-self: center;
      max-width: 600px;

      .section-title {
        color: theme-color('success-lighter');
      }
    }
  }
}

.benefits-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.05);
  padding: 3em 5em 2em;
  margin-bottom: 3em;

  .benefit {
    display: flex;

    &.center {
      flex-flow: column;

      .logo {
        width: auto;
        min-width: 250px;
        text-align: center;
        order: 1;
        > * {
          max-width: 60%;
          max-height: 15vh;
        }
      }
      .content {
        padding: 0;
      }
    }

    @media (min-width: $tabletV) {
      &,
      &.left {
        flex-flow: row;
        .logo {
          order: 1;
        }
        .content {
          padding-left: 2em;
        }
      }

      &.right {
        flex-flow: row;
        .logo {
          order: 3;
        }
        .content {
          padding-right: 2em;
        }
      }
    }

    .logo {
      flex: 1 0 auto;
      width: 250px;
      order: 1;
      padding-bottom: 3em;
      text-align: center;

      > * {
        width: 100%;
        max-width: 250px;
        max-height: 250px;
      }
    }

    .content {
      flex: 1 1 auto;
      order: 2;

      > *:first-child {
        margin: 0 0 1em;
      }
      p {
        margin-bottom: 1em;
      }
    }

    @media (max-width: $tabletV) {
      flex-flow: column;

      .logo {
        width: auto;
        text-align: center;
        order: 1;
        > * {
          width: auto;
          max-width: 70%;
          max-height: 250px;
        }
      }
      .content {
        padding: 0;
      }
    }
  }

  .footer {
    margin-top: 2em;
    text-align: right;
  }

  button,
  .button {
    border-radius: 1000px;
    padding: 10px 20px;
  }

  .bold {
    font-weight: bold;
  }
}

.optima-card {
  padding: 8px;
  border-radius: 10px;
  margin-bottom: 8px;
  color: #efefef;
  background-color: #009fce;
  border-left: solid 10px #312152;
}

.optima-header {
  margin: 0;
}

.optima-benefits-list {
  li::marker {
    color: #312152;
  }
}

.wellbeing-card {
  padding: 8px;
  border-radius: 10px;
  margin-bottom: 12px;
  color: black;
  border-left: solid 10px #c1d109;
  box-shadow: 1px 1px 5px 0px rgba(148, 148, 148, 1);
}
