@import '/src/scss/mixins';
@import '/src/scss/variables';

.cafm-check-good {
  color: theme-color('success-green');
}

.cafm-check-pending {
  color: war;
  color: theme-color('warning-strong');
}

.cafm-check-fail {
  color: war;
  color: theme-color('error');
}
