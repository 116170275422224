@import '/src/scss/_variables';

div.main-wrap {
  min-height: calc(100vh - #{$headerMenuHeight} - 65px);
}

div.Main {
  > nav.breadcrumbs-wrap {
    background-color: theme-color('primary-light');
    padding: 0 15px;
    position: relative;
    z-index: 1;

    .bp3-breadcrumbs {
      max-width: $pageWidth - 30px;
      margin: 0 auto;
    }
  }

  > div {
    > div {
      z-index: 1;
      position: relative;
      top: 0;
      max-width: $pageWidth;
      margin: 30px auto;

      &:not(.no-styling) {
        @extend .bottom-border;
        background-color: theme-color('contrast');
        padding: 30px 30px 50px;
      }
    }
  }
}

.capitalise {
  text-transform: capitalize;
}

.v-align-middle {
  vertical-align: middle;
}

div.background-image {
  position: absolute;
  top: $headerMenuHeight;
  left: 0;
  right: 0;
  z-index: 0;
  background: #f3f3f3 no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
  overflow: hidden;
  background-size: cover;
  mask-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 40vh,
    rgba(255, 255, 255, 0.3) 60vh,
    rgba(255, 255, 255, 0) 70vh,
    white 0%
  );
}

div.table-input {
  min-width: 150px;
  padding: 0px 8px;
  margin: 5px;
}

.black-and-white {
  filter: grayscale(100%);
}
