:root {
  --primary: #006073;
  --primary-hover: #004654;
  --secondary: #007877;
  --secondary-hover: #016060;
  --success: #0186d1;
  --success-hover: #0276b9;
  --text-color: #525252;
  --error: #ff0000;

  --app-background: url('/scss/assets/vantify-background.jpg') #065a68;

  // box
  --box-bg: white;
  --box-footer-bg: var(--primary);
  --box-footer-text: white;
  --box-footer-text-hover: #85c0ca;
  --reset-button-color: var(--primary);
  --reset-button-hover-color: var(--primary-hover);
  --create-account-bg: #eee;
  --box-input-border-color: #cccccc;
}
