$theme: (
  'primary': #006073,
  'primary-dark': #004654,
  'primary-darker': #013945,
  'primary-light': #a3c0c7,
  'primary-disabled': #00607e80,

  'secondary': #007877,
  'secondary-dark': #016060,
  'secondary-light': #c9ebeb,
  'secondary-lighter': #d8eaea,

  'dark-neutral': #525251,
  'neutral': #929290,
  'light-neutral': #efefef,

  'warning-light': #fff3cd,
  'warning-strong': #c7b375,

  'light-neutral-overlay': #d9d7d6,

  'contrast': #ffffff,
  'subtle-background': #fefefe,

  'error': #ed5565,
  'error-light': #fcdfe2,
  'error-lighter': #fae8ea,

  'success-green': #0f9960,
  'success-green-dark': #0d8050,
  'success-green-disabled': #0f996080,

  'success': #0186d1,
  'success-dark': #0276b9,
  'success-darker': #035787,
  'success-lighter': #5facd8,

  'danger': #a70000,
  'warning': #d9822b,
  'info': #137cbd,
  'teal': #106ba3,
);

$pageWidth: 1450px;
$headerMenuHeight: 90px;

// breakpoints
$tablet: 1248px;
$tabletV: 980px;
$mobile: 900px;
$mobileV: 690px;

// https://material.io/design/motion/speed.html#easing
$fastOutLinearInInterpolator: cubic-bezier(0.4, 0, 1, 1);
$fastOutSlowInInterpolator: cubic-bezier(0.4, 0, 0.2, 1);
$linearOutSlowInInterpolator: cubic-bezier(0, 0, 0.2, 1);
