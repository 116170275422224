@import 'scss/variables';
@import 'scss/mixins';

.assessment-buttons-container {
    button.bp3-button {
        min-height: 52px;
        position: relative;

        &:disabled {
            color: hsla(0,0%,100%,.6)
        }

        // NOT FIRST OR LAST BUTTONS
        &:not(:first-child):not(:last-child) {
            margin-left: 37px;
            border-radius: 0px;

            // PRIMARY STYLES
            &.bp3-intent-primary {
                background-color: theme-color('primary');

                &:hover {
                    background-color: theme-color('primary-dark');

                    &::after {
                        border-top-color: theme-color('primary-dark');
                    }

                    &::before {
                        border-bottom-color: theme-color('primary-dark');
                    }
                }

                &:disabled {
                    background-color: theme-color('primary-disabled');

                    &::after {
                        border-top-color: theme-color('primary-disabled');
                    }

                    &::before {
                        border-bottom-color: theme-color('primary-disabled');
                    }
                }

                &::after {
                    border-top-color: theme-color('primary');
                }

                &::before {
                    border-top-color: theme-color('primary');
                }
            }

            &::before {
                position: absolute;
                right: 100%;
                top: 0;
                content: "";
                border-bottom: 52px solid theme-color('primary');
                border-left: 30px solid transparent;
                margin-right: 0px;
            }

            &::after {
                position: absolute;
                left: 100%;
                top: 0;
                content: "";
                border-top: 52px solid theme-color('primary');
                border-right: 30px solid transparent;
                margin-left: 0px;
            }
        }

        // ONLY ONE BUTTON
        &:only-child {
            &::before {
                display: none;
            }
            &::after {
                display: none;
            }
        }

        // FIRST BUTTON
        &:first-child {
            border-top-left-radius: 50px;
            border-bottom-left-radius: 50px;
            padding-left: 30px;
            padding-right: 10px;
            margin-right: 0px;

            &::after {
                position: absolute;
                left: 100%;
                top: 0;
                content: "";
                border-top: 52px solid theme-color('primary');
                border-right: 30px solid transparent;
            }

            // PRIMARY STYLES
            &.bp3-intent-primary {
                background-color: theme-color('primary');

                &:hover {
                    background-color: theme-color('primary-dark');

                    &::after {
                        border-top-color: theme-color('primary-dark');
                    }
                }

                &:disabled {
                    background-color: theme-color('primary-disabled');

                    &::after {
                        border-top-color: theme-color('primary-disabled');
                    }
                }

                &::after {
                    border-top-color: theme-color('primary');
                }
            }
        }

        // LAST BUTTON
        &:last-child {
            border-top-right-radius: 50px;
            border-bottom-right-radius: 50px;
            padding-right: 30px;
            margin-left: 37px;

            &:only-child {
                margin-left: 30px;
            }

            &::before {
                position: absolute;
                right: 100%;
                top: 0;
                content: "";
                border-bottom: 52px solid theme-color('primary');
                border-left: 30px solid transparent;
                margin-right: 0px;
            }

            // SUCCESS STYLES
            &.bp3-intent-success {
                background-color: theme-color('success-green');

                &:hover {
                    background-color: theme-color('success-green-dark');

                    &::before {
                        border-bottom-color: theme-color('success-green-dark');
                    }
                }

                &:disabled {
                    background-color: theme-color('success-green-disabled');

                    &::before{
                        border-bottom-color: theme-color('success-green-disabled');
                    }
                }

                &::before {
                    border-bottom-color: theme-color('success-green');
                }
            }

            // PRIMARY STYLES
            &.bp3-intent-primary {
                background-color: theme-color('primary');

                &:hover {
                    background-color: theme-color('primary-dark');

                    &::before {
                        border-bottom-color: theme-color('primary-dark');
                    }
                }

                &:disabled {
                    background-color: theme-color('primary-disabled');

                    &::after {
                        border-bottom-color: theme-color('primary-disabled');
                    }
                }

                &::after {
                    border-bottom-color: theme-color('primary');
                }
            }
        }
    }
}