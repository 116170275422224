@import '/src/scss/variables';
@import '/src/scss/mixins';

.bp3-card.company-card {
  display: flex;
  width: 100%;
  background: white;
  text-decoration: none;
  margin-bottom: 10px;
  box-shadow: 2px 2px 5px 0 #ddd;
  color: inherit;
  position: relative;
  transition: background 350ms $linearOutSlowInInterpolator;

  &:hover {
    background: theme-color('secondary-lighter');

    .company-heading span {
      text-decoration: underline;
    }
  }

  div.actions {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .client-requirements {
      margin-right: 10px;
    }
    .add-to-supply-chain {
      font-size: 25px;
      margin: 0;
    }
  }

  .more-details {
    color: theme-color('secondary');

    &:hover {
      text-decoration: underline;
    }
  }

  .company-heading {
    margin: 0 0 10px;
    line-height: 1.2;
    font-size: 1.4em;
    padding-right: 260px;

    > div {
      vertical-align: middle;
      display: inline-block;

      span {
        vertical-align: bottom;
      }
    }

    &.client-heading {
      margin-top: -33px;
    }
  }

  div.logo {
    flex: 0 0 150px;
    width: 150px;
    height: 150px;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    img {
      width: 100%;
      max-width: 150px;
      max-height: 150px;
    }

    + .company-info {
      padding-left: 30px;
    }
  }

  .company-info {
    display: flex;
    flex-flow: column nowrap;
    padding-left: 185px;
    width: 100%;
    min-height: 150px;
  }

  .company-details {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
  }

  .badges {
    display: flex;

    > div {
      margin-right: 10px;
    }

    .rfa-rating-logo {
      width: 55px;
      height: 55px;
      position: relative;
      overflow: hidden;

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 90px;
        height: 90px;
        transform: translate(-50%, -50%);
      }
    }

    .logo {
      height: 100%;
      max-width: 100px;
      max-height: 55px;
      margin-top: 1px;

      &.cafm-logo {
        margin-top: 0;
        margin-bottom: 6px;
      }
    }
  }
}

.leaving-message {
  color: theme-color('error');
}

.renewing-message {
  color: theme-color('warning-strong');
}

button.bp3-button.bp3-intent-primary.remove-when-expired {
  background: none;
  color: theme-color('error');
  &:hover {
    color: theme-color('danger');
  }
}

a.table-link {
  color: theme-color('teal');
}

.grey {
  color: theme-color('neutral');
}

.company-card-rating-badge {
  margin-right: 10px;
}

.red-flag-tooltip {
  padding: 8px;
  background-color: none;
}
