@import '/src/scss/_variables';
:root {
  --decision-fade: #bbb;
}

div.RegisterWizard {
  min-height: 100%;
  min-width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: visible;
  overflow-y: hidden;
  background-size: cover;

  .bp3-panel-stack2 {
    width: 100%;
    max-width: 530px;
    overflow: visible;
    box-sizing: border-box;
    background: none;
    transition: height 250ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .bp3-panel-stack-view {
    overflow-y: visible;
    background: none;
    border: 0;
  }

  label.bp3-label {
    text-overflow: initial;
    line-height: 1.2 !important;
    white-space: normal;
  }

  .bp3-button + .bp3-button {
    margin-left: 5px;
  }

  .companyNumber {
    max-width: 300px;
  }
}

.wizard-page {
  display: flex;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: center;
  border-radius: 25px;

  > div {
    padding: 20px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  @media screen and (max-width: $mobileV) {
    div.header.small {
      flex-direction: column-reverse;
      align-items: center;
      gap: 12px;
    }
  }

  .header {
    z-index: 1;
    padding-top: 50px;
    min-height: 203px;
    text-align: center;
    border-radius: 25px 25px 0 0;
    background: var(--box-bg);

    @media screen and (max-width: $mobileV) {
      img.header-logo {
        max-width: 300px;
      }
    }

    .header-logo {
      max-width: 350px;
    }

    &.small {
      text-align: left;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 40px 20px 20px;
      min-height: auto;

      .header-desc {
        max-width: 300px;
        h3 {
          font-size: 150%;
        }
        span {
          font-size: 90%;
        }
      }
      .header-logo {
        max-width: 150px;
      }
    }
  }

  @media screen and (max-width: $mobileV) {
    div.body {
      padding: 0px 20px 20px;
    }

    div.body.user-details-register {
      padding: 0px 17px 20px;
    }
  }

  .body {
    padding: 0 40px 20px;
    background: var(--box-bg);

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
      padding: 0;
    }
    .body-desc {
      max-width: 350px;
      text-align: center;
      margin: 0 auto;
      h4 {
        font-weight: bold;
      }
      span {
        font-size: 90%;
      }
    }
    p {
      font-size: 110%;
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media screen and (max-width: $mobileV) {
    .decisions {
      .decision {
        align-items: center;
        padding: 0;

        + .decision {
          border-left: none;
          align-items: center;
          padding: 28px 0px;
        }
      }
    }
  }

  .decision {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-flow: column;
    padding: 20px 20px 20px 0;
    flex: 0 0 50%;
    box-sizing: border-box;

    + .decision {
      border-left: 1px solid var(--decision-fade);
      align-items: flex-start;
      padding: 20px 0 20px 20px;
    }

    > span {
      white-space: nowrap;
      color: var(--decision-fade);
    }
  }

  button.rounded {
    border-radius: 500px;
    min-width: 80px;
    width: auto;
  }

  .bp3-form-group.terms label {
    font-size: 110%;
    color: var(--text-color);
    font-family: inherit;

    + .bp3-checkbox {
      margin: 0 auto 0 15px;
    }
  }
}

.register-thank-you-message {
  text-align: center;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background: var(--box-bg);
  border-radius: 25px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  min-height: 300px;
  p {
    margin-bottom: 20px;
  }
}

.bp3-card.alert {
  padding: 10px 15px !important;
  margin-bottom: 20px !important;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 5px;
  }
  p {
    margin: 0;
  }
}

@media screen and (max-width: $mobileV) {
  .decisions {
    flex-direction: column;
    align-items: center;
  }
}
