@import "scss/mixins";
@import "scss/variables";

/* copied from Questions (old styling) */
.AccreditationAnswers {
  padding: 0 !important;
  > .inner { padding: 20px 30px 50px; }

  div.bp3-form-group {
    &.required {
      > label:not(.bp3-file-input) {
        text-decoration: none;
      }
    }
  }

  .errors-on-page {
    .question {
      font-weight: 600;
    }
  }

  .topic-description{
    padding-bottom: 5px;
  }

  .copy-answers  {
    padding-bottom: 5px;
  }

  div.bp3-form-group.bp3-inline.form-fill {

    &.question-label {
      font-size: 12px;
      flex-flow: row wrap;

      label.bp3-label {
        width: auto;
        // flex: 1 1 300px;
        padding-bottom: 20px;
      }

      .bp3-form-content {
        flex-flow: row wrap;
        flex: 1 1 100%;

        .question-label {
          width: 100%;
        }
      }
      .date-input, .number-input {
        width: 100%;
        display: flex;

        .bp3-popover-wrapper, .bp3-control-group {
          width: auto;
          flex: 0 0 150px;
        }
      }
    }

    &.review-form {
      label.bp3-label {
        width: 25px;
        margin-top: 8px;
        span.bp3-popover-wrapper {
          margin-top: 2px;
        }
      }

      .bp3-form-content .bp3-fill {
        flex: 1
      }
    }
  }

  .review-buttons {
    Button:first-of-type {
      margin-bottom: 0.5rem !important;
    }

    .bp3-button.bp3-intent-success.active {
      background-color: green;
    }
  }

  .AssessmentTypeInstanceHistory {
    .historyFields span {
      font-style: italic;
      font-weight: bold;
    }
  }
}

/* New Styling */
.vertical-questions {
  > section {
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 10px;
    background: theme-color(subtle-background);

    .bp3-form-group {
      flex: 0 0 auto;
      padding-left: 20px;
      margin: 0;
    }
  }

  > .header-section {
    border-radius: 0;
    box-shadow: 0 5px 10px -2px rgba(0,0,0,0.08);
    position: sticky;
    top: $headerMenuHeight;
    z-index: 11;

    .assessment-status {
      flex: 0 1 100%;

      .status-message:not(:empty) {
        display: inline-block;
        margin-right: 15px;
        font-weight: 600;
        font-size: 1.2em;
      }
    }
  }

  .topic-description {
    flex: 1 0 33%;
    line-height: 1.5;
    font-size: 16px;
    &:not(:empty){
      padding: 0 0.5rem 2rem;
    }
  }
  .topic-answer {
    flex: 1 0 66%;
  }

  div.bp3-card.question-error {
    padding: 15px;
    margin: 20px 0 5px;

    + .Question {
      color: theme-color("danger");
    }
  }

  .Question .question-review + .bp3-form-group {
    color: theme-color("warning");
  }

  .Question .bp3-form-group {
    display: block;

    label.bp3-label {
      display: inline-block;
    }
  }

  .bp3-button.bp3-intent-success.assess.me-2 {
    background-color: theme-color('success-green');

    &:hover {
        background-color: theme-color('success-green-dark');
    }

    &:disabled {
        background-color: theme-color('success-green-disabled');
    }
  }
}
